$fontDefault: var(--font-family);
$fontSize: var(--font-size);

.dx-datebox-wrapper-datetime .dx-timeview{
  margin: 0 15px !important;
}
.dx-datebox-wrapper-datetime .dx-timeview-clock{
  display: none !important;
}
.dx-datebox-wrapper-datetime .dx-box{
  flex-direction: column !important;
  align-items: center !important;
}
.dx-datebox-wrapper-datetime .dx-timeview-field{
  flex-direction: row !important;
  padding: 0 !important;
}
.dx-datebox-wrapper-calendar.dx-datebox-wrapper-datetime .dx-calendar{
  margin: 15px !important;
}

.user-contain{
  font-size: 2.4em;
}

.group-dropdown{
  display: flex;
  > div{
    width: 100%;
  }

  button{
    margin-left: 10px;
  }
}

.common-link{
  color: #337ab7;
  cursor: pointer;
  &:hover{
    text-decoration: underline;
  }
}

.staff-dropdown{
  max-width: 100%;
  cursor: pointer;
  .image-sale-person {
    width: 40px;
    min-width: 40px;
    height: 40px;
    min-height: 40px;
    border-radius: 40px;
    object-fit: cover;
    background: url("../images/icon-user-default.png") no-repeat center center;
    background-size: 40px 40px;
    margin-right: 5px;
  }
  .staff-info {
    width: 70%;
    span {
      display: -webkit-box;
      width: 100%;
      max-width: 100%;
      max-height: 32px;
      font-size: 12px;
      margin: 0 auto;
      line-height: 16px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre-wrap;
      &:nth-child(1){
        font-weight: bold;
      }
      &:nth-child(2), &:nth-child(3) {
        font-size: 10px;
        color: grey;
      }
    }
  }
}

#taskStatusW18F1014 {
  .dx-texteditor-input {
    background: transparent;
    color: white;
  }

  .dx-dropdowneditor-icon {
    color: white;
    background-color: transparent;
  }
}

#contactW18F1014{
 &.form-control{
   padding:0
 }
}

.tooltipComment{
  font-size: 11px;
  line-height: 20px;
  border-radius: 10px;
  position: absolute;
  right: -95px;
  top: 0px;
  padding: 10px;
  box-shadow: 0px 3px 5px 1px lightgrey;
  &:after {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 4px solid white;
    border-bottom: 4px solid white;
    border-right: 6px solid lightgrey;
    position: absolute;
    left: -7px;
    top: 25px;
  }
}

.scroll-popup .page-container{
  max-height: calc(100vh - 110px);
  overflow: scroll;

}

@media only screen and (max-width: 540px) {
  .dx-datebox-wrapper-calendar .dx-popup-normal{
    position: fixed;
    left: 50% !important;
    transform: translate(-50%, 30px) !important;
  }
  .popup-size-90 .modal-dialog{
    width: 98% !important;
  }
  #dashboard{
    .user-contain{
      font-size: 2em;
    }
    #dashboardButton{
      flex-wrap: nowrap;
      .mgr10{
        margin-right: 5px !important;
      }
    }
    .fixed-height{
      overflow: scroll;
    }
    .group-list-fixed{
      height: auto;
      overflow: initial;
    }
  }
}

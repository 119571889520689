.dx-treelist {
  border: 1px solid #dddddd;

  .dx-row-lines {
    td {
      border-bottom: 1px solid #e2d9d987 !important;
    }
  }

  .dx-header-row {
    td {
      text-align: center !important;
      font-weight: bold;
      color: #000000
    }
  }

}

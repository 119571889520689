.header-container {
  width: 100%;
  min-height: 60px;
  //border-bottom: 1px solid #B7B7B7;
  display: flex;
  padding: 0 24px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  background-color: white;
  box-shadow: 0 0 5px 0 lightgrey;
  .fa-bars{

  }
  .menu-group{
    background-image: linear-gradient(to right, #6FA3F8 , #3EB1FE);
    position: relative;
    width: 560px;
    max-width: 560px;
    &:before{
      position: absolute;
      left: -40px;
      top:0;
      content:'';
      width: 0;
      height: 0;
      border-bottom: 60px solid #6FA3F8;
      border-left: 40px solid transparent;
    }
    &:after{
      content:'';
      width: 24px;
      height: 60px;
      position: absolute;
      right: -24px;
      background-color: #3EB1FE;
      top:0;
    }
  }
  .info-group::before{
    width: 0;
    height: 0;
    border-top: 100px solid red;
    border-left: 100px solid transparent;
  }
  .info-group{
    background-color: white;
    width: calc(100% - 570px);
  }

  .display-menusidebar.active{
    .menu-fixed-bg {
      display: block;
    }

    .menu-fixed {
      left: 0;
    }
  }
  .display-menusidebar {

    .icon-menu{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      color: $crmBackGroundColorMain;
      i:hover,i:before:hover{
        text-decoration: none;
      }
    }

    .menu-fixed-bg {
      width: 100%;
      background: rgba(0, 0, 0, 0.4);
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      display: none;
      z-index: 11;
      transition: all 0.5s;
    }

    .menu-fixed {
      overflow: hidden;
      width: 300px;
      background-color: $crmBackGroundColorMain;
      height: 100%;
      z-index: 12;
      position: fixed;
      top: 0;
      transition: all 0.5s;
      left: - 300px;
      //left: 0;
      display: flex;
      flex-direction:column ;

      .user-name-profile{
        color: white;
        font-size: 14px;
      }

      .nav-item{
        padding: 0;
        transition: all 1s;
        overflow: hidden;
        margin-bottom: 0 !important;
        .fas{
          color: white;
          margin-right: 10px;
        }
      }

      .fa-caret-right{
        display: none;
      }
      .sub-menu{
        list-style: none;
        padding-left: 0;
        a{
          width: 100%;
          padding: 0 0 0 10px !important;
          display: flex;
          height: 30px !important;
          align-items: center;
        }
      }

      .menu-list{
        overflow-y: auto;
        padding-top: 20px;
      }

      .menu-list, .menu-fixed_body {
        a, span, .like-a {
          color: white;
          height: 40px;
          padding: 10px;
          cursor: pointer;
          text-decoration: none;
        }
      }

      .avatar, .avatar-mob {
        display: flex;
        align-items: center;
        &:hover{
          text-decoration: none !important;
        }
        img{
          margin: 10px;
        }
      }

      .avatar-mob{
        border-bottom: 1px solid royalblue;
      }

      .menu-fixed_body {
        color: white;
      }

      .menu-item-child, .menu-item-child-child{
        height: 30px !important;
        padding: 0 0 0 10px !important;
        line-height: 30px !important;
        width: 100%;
        i{
          color: white !important;
          margin-right: 5px;
        }
      }
      .menu-item-child-child{
        margin-left: 20px;
      }

      .menu-item-child.active, .menu-item-child-child.active , .menu-item-child-home.active  {
        background: lightskyblue;
      }
    }
  }
  .bg-header{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    left: 0;
    top: 0;
    //filter: grayscale(100%);
  }

  @keyframes fadeIn{
    0% {opacity:0;}
    100% {opacity:1;}
  }

  .text-header{
    font-size: 24px;
    line-height: 29px;
    font-weight: bold;
    color: #242666;
    margin-left: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: fadeIn ease 5s;
    white-space: pre;
  }

  .menu-view{
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .avatar.active,.avatar-mob.active{
    border-bottom: 6px solid;
  }

  .line-profile{
    height: 4px;
    width: 36px;
    background-color: #F22858;
    position: absolute;
    bottom: -8px;
    right: 4px;
  }

  .popup-profile{
    z-index: 1000;
    position: absolute;
    top: 60px;
    right: 0;
    img{
      width: 22px;
      object-fit: contain;
    }
    .dropdown-body, .dropdown-footer{
      cursor: pointer;
      span{
        padding-left: 12px;
        margin-left: 12px;
        border-left: 1px solid #707070;
        line-height: 16px;
        height: 16px;
      }
      li{
        padding: 0 !important;
        font-size: 15px;
        font-family: Roboto;
      }
      .text-truncate{
        align-items: center;
        &:first-child{
          //margin-bottom: 15px;
        }
      }
      .liUser{
        overflow: hidden;
      }
    }
    .dropdown-change{
      width: 212px !important;
      height: 55px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 0px 10px 0px lightgrey;
      border: none ;
    }
  }

  .img-avatar{
    border-radius: 30px;
    height: 44px;
    width: 44px;
    min-height: 44px;
    min-width: 44px;
    object-fit: contain;
    margin-left: 30px;
    padding: 4px;
    background: white;
  }

  .header-name {
    padding: 5px 0;
    align-items: center;
    display: flex;
    text-align: right;

    span {
      max-width: 100px;
      overflow: hidden;
      max-height: 20px;
      line-height: 16px;
      text-overflow: ellipsis;
      margin: 3px 0;
    }
  }

  .header-name:hover, .header-name:active {
    background-color: #eee;
    color: $btnFontColor;
  }

  .header-logo {
    height: 36px;
    object-fit: cover;
    margin: 12px 0;
  }

  .dropdown-header {
    position: relative !important;
    text-align: center !important;
    min-width: 180px !important;
    width: auto;
    padding: 10px 9px !important;
    font-size: 12px !important;
    white-space: nowrap !important;
  }

  .notify-header {
    background-color: #e3e6e8;
    span {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .notify-tabs {
    font-size: 12px;
    li {
      a {
        border-radius: 20px;
        margin: 5px 2px 5px 5px;
        padding: 5px 10px;
        border: 1px solid #ddd;
      }
      &.active {
        a {
          background-color: #3298fd4f;
          color: #337ab7;
          font-weight: 500;
          border: none;
        }
      }
    }
  }
}

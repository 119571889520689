#Activity-Component {
  height: 100%;

  #loading-activity {
    position: absolute;
    left: 50%;
    top: 50%;
  }

  #close-activity {
    position: absolute;
    top: -7px;
    left: -7px;
    opacity: 0.7;
    display: none;
    width: 23px;
    height: 23px;
    cursor: pointer;
  }

  #Activity-Title {
    color: #ff9d62c7;
    font-weight: bold;
    margin-bottom: 15px;
  }

  .Activity-Content-Info {
    display: flex;
    align-items: center;

    .Square-Info{
      .activity-content{
        color:white;
        font-size: 12px;
        font-weight: bold;
      }
    }
    .activity-user-image {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      object-fit: cover;
      max-width: 100%;
    }
  }

  .Activity-Content-Detail {
    display: flex;
    align-items: center;

    .Activity-Content-DetailFrom {
      font-size: 14px;
      max-width: 50%;
      word-break: break-word;
      white-space: pre-wrap;

      .Square-Info {
        .activity-content {
          color: rgb(7, 71, 166);
        }
      }
    }

    .Activity-Content-DetailTo {
      font-size: 14px;
      max-width: 50%;
      word-break: break-word;
      white-space: pre-wrap;

      .Square-Info {
        .activity-content {
          color: rgb(66, 82, 110);
        }
      }
    }
  }

  #Activity-Container {
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100vh - 150px);
  }

  .Activity-Info {
    display: flex;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid silver;
    border-bottom-style: dotted;

    .Activity-Date {
      text-align: end;
    }

    .Activity-User-Image {
      border-radius: 50%;
      width: 40px;
      height: 40px;
      margin-right: 5px;
      object-fit: cover;
    }

    .Activity-User-Name {
      font-weight: bold;
      color: #4076ca;
      font-size: 16px;
    }

    .Activity-User-Content-Name {
      font-weight: bold;
      font-size: 16px;
    }

    .Activity-User-Content {
      display: flex;
      justify-content: center;
      flex-flow: column;
      flex-basis: 95%;

      .activity-content {
        p {
          margin: 0 0 2px !important;
        }

        img {
          width: 100%;
          object-fit: cover;
          height: 100%
        }

        * {
          margin: 0 !important;
        }
      }

      .Activity-Arrow {
        margin: 0 5px;
      }
    }

  }

  @media only screen and (max-width: 1200px) {
    .Activity-Info {
      flex-direction: column;

      .Activity-User-Image {
        width: 55px;
        height: 55px;
        margin: 0 auto 10px auto;
      }
    }
  }
}

.history-hidden {
  width: 0 !important;
}

.popup-history {
  position: absolute !important;
  top: 40px;
  right: -15px;
  background-color: #ffffff;
  box-shadow: 1px 0 5px 1px #ddd;
  height: 95%;
  padding: 15px;
  width: 100%;

  #close-activity {
    display: block !important;
  }
}
.toolbar-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 5px 0;
  background: $dataGridHeaderBgColor;
  border-top: 1px solid #f1eded;
  border-bottom: 1px solid #f1eded;

  #dropdown-toolbar {
    border-radius: 5px;
  }

  .btn {
    padding: 3px 7px !important;
    margin: 0 1px;
    background: $btnToolbarBackgroundColor;
    color: $btnToolbarFontColor;
  }

  .btn[disabled],
  .btn[disabled]:hover,
  .btn[disabled]:active,
  .btn[disabled]:active:hover,
  .btn[disabled]:active:focus,
  .btn[disabled]:focus {
    background: $btnToolbarDisableBackgroundColor;
    color: $btnToolbarDisableFontColor;
  }

  .btn:hover {
    background: $btnToolbarHoverColor;
    color: $btnToolbarActionFontColor;
  }

  .btn:active {
    background: $btnToolbarActiveColor;
    color: $btnToolbarActionFontColor;
  }

  .btn:focus,
  .btn-default:active:hover,
  .btn-default:active:focus {
    background: $btnToolbarFocusColor;
    color: $btnToolbarActionFontColor;
  }

  .Rbutton-Toolbar {
    margin-left: auto;
    margin-right: 5px;

    .dropdown-menu {
      left: unset;
      right: 0;
    }
  }

  .Lbutton-Toolbar {
    margin-left: 5px;

  }

  .advanced-search-clear-button:hover {
    color: #2a76bb
  }
}


.user-container{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 20px 20px 0 0;
}
.pdl5{
  padding-left: 5px !important;
}
.pdb5{
  padding-bottom: 5px !important;
}

.pdb10{
  padding-bottom: 10px !important;
}
.fts12{
  font-size: 12px !important;
}
div .text-overflow{
  white-space: nowrap;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
}
div .text-overflow-hg{
  width: auto;
  text-overflow: ellipsis;
  max-height: 35px;
  overflow: hidden;
}

div .text-overflowTitle{
  white-space: nowrap;
  width: auto;
  padding-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pdt50{
  padding-top: 50px !important;

}
.mrgt50{
  margin-top: 70px !important;
  justify-content: flex-end !important;

}
.mgdl{
 margin: 0 auto !important;
  display: block !important;
}

.imageUs {
  display: block;
  width: auto;
  max-height: 110px;
}
.imageUse {
  width: 100%;
  height:120px;
  object-fit: cover;
  border-radius: 4px;
}

.imgSales{
  width: 100%;
  max-height: 200px;
  object-fit: cover;
}

#controlled-tab-example {
  min-width: 500px;
}
.success{
  color:#28a745;
}

.primary{
  color:#007bff;
}

.mgl20{
  margin-left: 20px !important;
}

.icon-image{
  position: absolute;
  color: red;
  font-size: 50px;
  bottom: 0;
  right: 0;
}
.pos{
  position: relative !important;
}

.block-users-employee {
  position: absolute;
  top: 50%;
  font-size: 17px;
  color: red;
  border: 1px solid red;
  transform: rotate(35deg) translate(-50%, -50%);
  left: 50%;
  transform-origin: left center;
  padding: 4px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  width: 134px;
  text-align: center;
  text-transform: uppercase;
}

.user-hover-employee {
  transition: all 0.2s;
  border: 1px solid #dbd9d9;
  width: 100%;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
  position: relative;
  overflow: hidden;
  &:hover{
    cursor: pointer;
    transform: scale(1.05);
  }
}

#user-employee-left {
  width: 40%;
  border-right: 2px solid #dbd9d9;
  padding: 0;

  .user-image-employee {
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
  }
}

#user-employee-right {
  width: 60%;
  height: 100%;

  .user-right-content {
    width: 100%;
    height: 100%;
    padding: 10px 0 10px 0;
    position: relative;

    .fts16{
      font-size: 16px;
    }
  }

  .content_delete {
    top: 7px;
    right: 15px;
    position: absolute;
    font-size: 15px;
    z-index: 100;
    transition: all 0.1s;
    color: #EB0C29;
  }

  .content_delete:hover{
    transform: scale(1.1);
  }

  .content-delete-bor{
    width: 81px;
    height: 71px;
    border: 2px solid #dbd9d9;
    border-radius: 50%;
    display: block;
    background: #d6d6d6;
    opacity: 0.2;
    position: absolute;
    right: -45px;
    top: -23px;
  }

  .content_user_right{
    color: black;
    font-size: 12px;
    padding: 0 0 10px 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .fts14{
    font-size: 14px;
  }
  .pdr3{
    padding-right: 5px;
  }
  .user_mail_content{
    color: #F8A000;
  }
}

.user_paging_employee{
  height: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: -15px;
}

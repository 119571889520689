.menu {
  margin-left: 10px;
  z-index: 1000;

  .nav {
    position: relative;
    width: 100%;
    top: 0;
    box-shadow: none;
    background-color: transparent;

    .ico-right {
      display: none;
    }
  }
  .nav>li{
    float: left;
    margin-right: 15px;
  }

  .nav>li>a{
    text-transform: uppercase;
    color: #212529;
    white-space:nowrap;
  }

  .nav li{
    position: relative;
    list-style:none;
    display: flex;
    justify-content: space-between;
  }

  .nav li a{
    padding: 14px 5px;
    line-height: 20px;
    display: inline-block;
    white-space:nowrap;
  }
  .nav .sub-menu li a {
    padding: 6px 10px;
    white-space:nowrap;
  }

  .nav .sub-menu{
    display: none;
    position: absolute;
    top: 0;
    left: 100%;
    min-width: 180px;
    background-color: #eee;
    padding: 0;

    .ico-right {
      display: block;
      float: right;
      margin: 10px 5px 10px 10px;
    }
  }

  .nav li:hover>.sub-menu{
    display: block;
  }

  .nav .sub-menu li:hover {
    background-color: #ceebfc;
  }

  .nav>li>.sub-menu{
    top: 48px;
    left: 0;
  }
}

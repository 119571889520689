@function hexToRGB($hex) {
  @return red($hex), green($hex), blue($hex);
}
body {
  --primary-bg-color: #{hexToRGB(#111D5E)};
  --primary-bg-color-light: #{hexToRGB(#EFF5FF)};
  --primary-font-color: #fafafa;
  --secondary-bg-color: #{hexToRGB(#000000)};
  --secondary-font-color: #ffffff;

  --success-bg-color: #{hexToRGB(#00C875)};
  --info-bg-color: #{hexToRGB(#0095FF)};
  --warning-bg-color: #{hexToRGB(#FFAA00)};
  --danger-bg-color: #{hexToRGB(#FF3D71)};
  --placeholder-color: #{hexToRGB(#7F828E)};

  --system-rest-color: #{hexToRGB(#7F828E)};
}

$black: #333;
$orange: #ef4d3c;
$red: red;
$default: #4e73df;
$grey: #999;
$lightgrey: lightgrey;
$bghover: #f5f5f5;
$white: #fff;
$yellow: #f39c12;

$normal : 0.85rem;

$textColor: #222B45;
$subTextColor: #687799;
$textLabelColor: #505C80;
$textHoverColor: #111D5E;

$fontColorDefault: $textColor;
$fontColor: #fafafa;

$defaultColor: #dddddd;

$primaryColor: rgba(var(--primary-bg-color), 1);
$primaryHoverColor: rgba(var(--primary-bg-color), 0.17);
$primaryLightColor: rgba(var(--primary-bg-color-light), 1);
$primaryFontColor: var(--primary-font-color);
$secondaryColor: rgba(var(--secondary-bg-color), 1);
$secondaryHoverColor: rgba(var(--secondary-bg-color), 0.17);
$secondaryLightColor: rgba(var(--secondary-bg-color), 0.20);
$secondaryFontColor: var(--secondary-font-color);

//general
$successColor: rgba(var(--success-bg-color), 1);
$infoColor: rgba(var(--info-bg-color), 1);
$warningColor: rgba(var(--warning-bg-color), 1);
$dangerColor: rgba(var(--danger-bg-color), 1);

$systemRest: rgba(var(--system-rest-color), 1);

//hover color
$hoverMain: #EFF5FF;
$hoverFill: #EFF5FF;
$disabledFill: #E7EAF0;
$hoverColorDefault: #DFEFFF;
$hoverColor: #EFF5FF;

$placeholderColor: rgba(var(--placeholder-color), 1);


$dataGridCellFontColor: #000000;
$dataGridHeaderBgColor: #E9EAF0;
$dataGridHeaderFontColor: $textColor;
$dataGridFontColor: $textColor;
$dataGridBgEvenColor: #FAFBFC;
$dataGridBgOddColor: #FFFFFF;
$dataGridRowHoverColor: $hoverColorDefault;
$dataGridRowFocusColor: #A2CFFF;
$dataGridSelectedBgColor: #A2CFFF;
$dataGridSelectedFontColor: $textColor;
$dataGridCheckBoxBgColor: $textColor;
$dataGridCheckBoxFontColor: $textColor;
$dataGridActionButtonColor: #E4E9F2;
$dataGridBorderColor: #D4D5D9;

$gridContainerBorderColor: #f5f5f5;
$gridContainerBgColor: #fff;

$pagingPerPageBackground: #f8f7f7;
$pagingPerPageColor: $textColor;
$pagingInfoColor: $textColor;

$btnToolbarActionFontColor: #e4edf3;
$btnToolbarFontColor: #000000;
$btnToolbarBackgroundColor: #f8f7f7;
$btnToolbarHoverColor: #449bea;
$btnToolbarActiveColor: #3375b1;
$btnToolbarFocusColor: #2b76b9;
$btnToolbarDisableBackgroundColor: #eef2f5c7;
$btnToolbarDisableFontColor: #b3bbc1;

$red: #FE0101;
$red2: #f24646;

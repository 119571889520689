
.dashboard-view{
  margin-top: 15px;
  position: relative;
  .card-setting-container {
    cursor: pointer;
    perspective: 600;
    position: relative;
  }
  .card-setting {
    height: 100%;
    transform-style: preserve-3d;
    transition: all 1s ease-in-out;
    border: none;
    width: 100%;
  }
  .card-setting.on-setting {
    transform: rotateY(180deg);
  }
  .card-setting .side {
    backface-visibility: hidden;
    border-radius: 6px;
    padding: 10px;
    height: 100%;
    position: absolute;
    overflow: hidden;
    width: 100%;
  }
  .card-setting .back {
    color: #0087cc;
    transform: rotateY(180deg);
  }
  .box-item{
    padding: 10px;
    transition: all 0.5s;
    background: white;
    height: 400px;
  }
  .box-body{
    border-radius: 3px;
    border: 1px solid ghostwhite;
    height: 100%;
    box-shadow: 0px 1px 8px darkgrey;
  }
  .title-view{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .title-text{
    font-size: 15px;
    line-height: 20px;
    max-height: 40px;
    overflow: hidden;
    color: $crmBackGroundColorMain;
  }
  .action-title{
    display: flex;
    i{
      font-size: 15px;
      color: $crmBackGroundColorMain;
      cursor: pointer;
      &:first-child{
        margin-right: 10px;
      }
    }
  }
  .minimize-view{
    position: fixed;
    width: 0;
    height: 0;
    left: 0;
    bottom: 0;
    opacity: 0;
    z-index: 1;
  }
  .maximize-view{
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    z-index: 1000;
    opacity: 1 !important;
  }
  .chart-view{
    margin-top: 50px;
  }
  .chart-column, .chart-view{
    padding: 20px 0;
    height: calc(100% - 40px);
    .chartjs-render-monitor{
      max-height: 80%;
      object-fit: contain;
    }
  }
  .col-sm-6.columns-special{
    width: 100%;
  }
  .setting-dashboard .group-radio{
    border: 1px solid $btnBgColor;
    border-radius: 9px;
    padding: 10px;
    margin: 10px 0;
  }
  .setting-dashboard #btnSubmit{
    background: $btnBgColor;
    border: $btnBorder;
  }
  .setting-dashboard .fa-check{
    font-size: 15px;
    margin-right: 5px;
    color: #2c5115;
  }
  .setting-dashboard .row:not(.align-right){
    height: 50px;
    display: flex;
    align-items: center;
  }
  .setting-dashboard .dx-scrollable-content{
    max-width: 100%;
  }
  .setting-dashboard .radio{
    margin: 0;
  }
  .dx-datagrid-table .dx-header-row td{
    padding: 5px 10px;
    background: rgba(255,99,132,0.2) !important;
  }
  .dx-datagrid .dx-row > td{
    padding: 5px;
    color: dimgrey;
  }
  .dx-datagrid-headers .dx-header-row td .dx-datagrid-text-content {
    color: #337ab7;
  }
}
@import "color";
@import "general";
@import '_color_crm01';
@import '_data-grid';
@import '_menu';
@import '_modal';
@import '_toolbar';
@import '_user-container';
@import '_image-type';
@import '_list';
@import '_user-profile';
@import '_multi-toggle.scss';
@import '_admin.scss';
@import '_dash-board.scss';
@import 'step-progress-bar';
@import '_W17F0043.scss';
@import '_header.scss';
@import '_error500';
@import '_react-tags';
@import '_general';
@import '_acitivty-component.scss';
@import '_tree-list.scss';
@import '_advance-search.scss';
@import '_button-switch.scss';
@import '_login.scss';
@import "spacing";
@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css');

body {
  margin: 0;
  padding: 0;
  font-family: 'Helvetica Neue','Segoe UI',Helvetica,Verdana,sans-serif;
  background-color: white;

  //-webkit-touch-callout: none;
  //-webkit-user-select: none;
  //-moz-user-select: none;
  //-ms-user-select: none;
  //user-select: none;
}

html, .adminRoot, .body, #app div {
  max-height: 100vh;
  font-size: 14px;
  //letter-spacing: 0.4px;
}

body, #root, .body-container {
  min-height: 100vh;
}

a:hover, a:visited, a:link, a:active {
  text-decoration: none;
}

*:focus {
  outline: none !important;
}

.display_row {
  display: flex;
}

.display_col {
  display: flex;
  flex-direction: column;
}

.align-left {
  align-items: flex-start;
}

.align-right {
  align-items: flex-end;
}

.align-center {
  align-items: center;
}

.align-around {
  justify-content: space-around;
}

.align-between {
  justify-content: space-between;
}

.valign-middle {
  justify-content: center;
}

.valign-top {
  justify-content: flex-start;
}

.valign-bottom {
  justify-content: flex-end;
}

a {
  cursor: pointer;
}

.pos_fixed {
  position: fixed !important;
}

.full_w {
  width: 100% !important;
}

.popup-modal {
  width: 100% !important;
}

.btn-close {
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.txt-one-line {
  max-height: 2.8rem;
  line-height: 1.4rem;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.txt-two-line {
  max-height: 2.8rem;
  line-height: 1.4rem;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.btn-close:hover {
  opacity: 0.7;
}

.error {
  color: red;
}

.success {
  color: #A2DDB7;
}

.text-purple {
  color: green;
}

.modal {
  overflow: auto !important;

  .modal-dialog {
    margin: 30px auto;
  }
}

#frmW18F1031 {
  .tab-content {
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    padding: 5px 15px;
  }
}

.form-input {
  width: 100%;
  padding-right: 30px;
  position: relative;
}

.form-control-feedback {
  right: 20px !important;
  bottom: 0 !important;
  top: auto;
}

.promotion .form-control-feedback {
  right: -3px !important;
}

.has-feedback .form-control {
  padding-right: 10px !important;
}

.them {
  right: 10px !important;
}

.table-new {
  color: #333;
  cursor: pointer;
  border: 1px solid #ccc;
  padding: 0 10px;
  border-radius: 5px;
  align-items: center;
  display: flex;
}

.table-image {
  height: 70px;
  object-fit: cover;
  margin-right: 5px;
  margin-bottom: 5px;
}

.table-video {
  width: 70px;
  height: 70px;
  object-fit: contain;
  background-color: black;
}

.text-area {
  height: 200px !important;
}

.table-btn-remove {
  color: #333;
  cursor: pointer;
  border: 1px solid #ccc;
  padding: 0;
  width: 75px;
  margin: 0 2px;
  border-radius: 3px;
}

.btn-group > .btn {
  border-radius: 0;
}

#branchSort, #bSortType {
  padding: 1px 12px;
}

textarea {
  resize: vertical;
}

.wrap {
  flex-wrap: wrap;
  display: flex;
}

.form-row {
  margin-right: 30px;
  width: 20%;
}

.form-row-2 {
  margin-right: 30px;
  width: 40%;
}

.bold {
  font-weight: bold;
}

.loading {
  width: 100%;
  text-align: center;
  margin: 150px auto;
}

.tooltip [class$="inner"] {
  background-color: #d6d6d6;
  border: 1px solid #d6d6d6;
  color: #2f78b9;
}

.tooltip [class$="arrow"] {
  border-bottom: 5px solid #d6d6d6 !important;
}

.login-container {
  display: flex;
  width: 100%;
  margin: 0;
  padding: 0;
  align-items: center;
  justify-content: center;
  background: url("../images/crm-bg.png") center no-repeat;
  height: 100vh;

  .form-group {
    margin-bottom: 5px;
  }

  .has-error .form-control {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    box-shadow: none !important;
  }
}

.login-bound {
  display: flex;
  width: 400px;
  height: 660px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.35);
}

.login-label {
  display: flex;
  width: 85%;
  margin-top: 40px;
  margin-bottom: 20px;
  font-size: 20px;
  color: #737373;
}

.login-form {
  display: flex;
  flex-direction: column;
  width: 85%;
  margin-bottom: 50px;
}

.login-input {
  border-top: none;
  border-left: none;
  border-right: none;
  box-shadow: none;
  border-radius: 0;
  -webkit-box-shadow: none;
}

//.login-bt {
//  //margin-top: 40px;
//  height: 40px;
//  text-transform: uppercase;
//  font-size: 20px !important;
//  outline: none !important;
//  border: none !important;
//  background: linear-gradient(#67ae55, #578843);
//  background-color: #69a74e !important;
//  border-color: #3b6e22 #3b6e22 #2c5115;
//  border-radius: 5px;
//  color: #fff !important;
//  cursor: pointer;
//  display: inline-block;
//  letter-spacing: 1px;
//  position: relative;
//  text-shadow: 0 1px 2px rgba(0, 0, 0, .5);
//  box-shadow: 0 1px 1px rgb(244, 218, 17);
//}

.flag-focus {
  border-bottom: 1px solid;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

//.login-bt:hover {
//  color: white !important;
//  outline: none !important;
//  border: none !important;
//  background-image: none;
//  opacity: 0.9;
//  box-shadow: 0 1px 1px rgb(244, 218, 17);
//}
//
//.login-bt:active,
//.login-bt:focus,
//.login-bt:active,
//.login-bt:disabled {
//  color: white !important;
//  outline: none !important;
//  border: none !important;
//  background-image: none;
//  background-color: #f4b01b !important;
//  box-shadow: 0 1px 1px rgb(244, 218, 17);
//}

//.login-input:active,
//.login-input:focus,
//.login-input.active {
//  background-image: none;
//  outline: none !important;
//  box-shadow: none !important;
//}

.header-container {
  width: 100%;
  height: 50px;
  background-color: #f8f8f8;
  border: 1px solid #e7e7e7;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .txt-number-badge {
    position: absolute;
    font-size: 10px;
    color: white;
    background: red;
    width: 16px;
    height: 16px;
    border-radius: 16px;
    text-align: center;
    line-height: 15px;
    top: 0;
    right: 3px;
  }
}

.header-left {
  flex-direction: row;
  display: flex !important;
  justify-content: flex-start !important;
}

.header-right {
  display: flex;
  justify-content: flex-end !important;
}

.text-red {
  color: red;
}

.text-warnings {
  color: #ffc107;
}

.clr-cus {
  color: #337ab7 !important;
}

.pdr5 {
  padding-right: 5px !important;
}

.pdl10 {
  padding-left: 10px !important;
}

.hide {
  display: none;
}

.avatar {
  position: relative;
  display: inline-block;
}

.img-avatar, .avatar > img, .img-circle {
  max-width: 100%;
  width: 40px !important;
  height: 40px;
  border-radius: 50%;
  object-position: top !important;
  object-fit: cover !important;
}

.display-menusidebar {
  display: flex;
}

.display-menutop {
  flex-direction: row;
}

@media only screen and (max-width: 900px) {
  .menu {
    display: none;
  }
  .display-menusidebar {
    display: flex;
  }
}

@media only screen and (min-width: 900px) {
  .menu {
    display: flex;
  }
  .display-menusidebar {
    display: none;
  }
}

.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.navbar-toggler {
  padding: .25rem .75rem;
  font-size: 1.09375rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: .25rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
}

.fontsize {
  font-size: 15px !important;
}

.dropdown-header {
  position: relative !important;
  text-align: center !important;
  min-width: 180px !important;
  width: auto;
  padding: 10px 9px !important;
  font-size: 12px !important;
  background-color: #F0F3F5;
  white-space: nowrap !important;
}

.dropdown-body {
  position: relative;
  width: auto;
  min-height: 400px;
  max-height: 500px;
  overflow: auto;

  li {
    border-bottom: 1px solid #e3e6e8 !important;
    padding: 5px 10px !important;
    display: flex;
    flex-direction: column;
  }
}

.dropdown-footer {
  position: relative;
  width: auto;
  font-size: 12px !important;
  padding: 10px 9px;
  margin-top: 0 !important;
  color: #F0F3F5;
  text-decoration: underline;
  border-top: 1px solid #dddfe2;
}

.total-notify {
  color: $crmBackGroundColorMain !important;
  text-align: left !important;
  padding: 6px 9px !important;
  background-color: #e3e6e8 !important;
  border-bottom: 1px solid lightgrey;
}

.div-notify {
  z-index: 1000;
  width: 345px;
  position: absolute;
  top: 46px;
  right: -40px;
  background-color: white;
  box-shadow: 0 3px 5px 1px #cdcdcd;
  padding: 0;
  border-radius: 3px;

  .text-no-data {
    line-height: 100px;
    text-align: center;
    display: block;
    width: 100%;
  }

  .text-content {
    display: block;
    display: -webkit-box;
    width: 100%;
    max-width: 100%;
    max-height: 32px;
    font-size: 12px;
    margin: 0 auto;
    line-height: 16px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    b {
      color: $crmBackGroundColorMain;
    }
  }

  .item-notify {
    border-bottom: 1px solid #dddfe2;
    padding: 15px 0 10px 0;
    cursor: pointer;
    flex-direction: row !important;

    .avatar-sender {
      width: 50px;
      height: 50px;
      border-radius: 100px;
      object-fit: cover;
      margin-right: 10px
    }
  }

  .text-truncate {
    font-size: 9px;
    color: grey;
    text-align: right;
  }
}

.dropdown-filter {
  z-index: 1000;
  position: absolute;
  top: 35px;
  left: 0;
  width: 100%;
  overflow: hidden;
  padding: 0;
  pointer-events: auto;

  .dropdown-change {
    padding: 10px;
  }

  .dx-datebox {
    .dx-placeholder {
      padding-left: 10px;
    }

    .dx-texteditor-input {
      margin-left: 10px;
    }
  }
}

.dropdown-change {
  width: auto !important;
  position: relative;
  max-width: 250px !important;
  font-size: 12px !important;
  background-color: #ffffff !important;
  border: 1px solid #c8ced3 !important;
  border-radius: 5px !important;
  overflow: hidden;
  box-shadow: 0 3px 5px 1px #cdcdcd;
}

fieldset {
  border-radius: 0;
}

.header-name {
  padding: 5px 0;
  align-items: center;
  display: flex;
  text-align: right;

  span {
    max-width: 100px;
    overflow: hidden;
    max-height: 20px;
    line-height: 15px;
    text-overflow: ellipsis;
    margin: 3px 0;
  }
}

.header-name:hover, .img-avatar:hover {
  transition: all 0.5s;
  transform: scale(1.1);
}

.header-logo {
  height: 35px;
  object-fit: cover;
  margin-top: 7px;
  margin-right: 5px;
}

.main {
  width: 100%;
}

.login_title {
  font-size: 35px !important;
  color: #0074d9 !important;
}

.login {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.register_error {
  color: red;
}

.login_btn_login {
  width: 100%;
  color: white !important;
  background-image: none !important;
  border-color: transparent !important;
  box-shadow: none !important;
  background-color: #26953a !important;
  margin-top: 15px;
  text-shadow: none !important;
  font-weight: bold;
  position: relative;

  .ico_face {
    font-size: 22px;
    position: absolute;
    left: 10px;
  }
}

.select {
  -webkit-touch-callout: default;
  -webkit-user-select: auto;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.fu-label {
  display: flex;
  width: 100%;
  color: #8e8e8e;
  margin-bottom: 5px;
}

.cf-feedback {
  display: flex;
  height: 1px;
  position: relative;
}

.cf-error {
  color: red;
  width: 100%;
  display: flex;
}

.opt-left {
  .form-group label {
    margin-left: 20px !important;
  }
}

.tff-1 {
  width: 15%;
  max-width: 120px;
}

.tff-2 {
  width: 51%;
}

.tff-3 {
  width: 13%;
}

.tff-4 {
  width: 13%;
  max-width: 100px;
}

.fu-val {
  .tff-3 {
    width: 20%;
  }
}

.page-container {
  margin: 15px 15px 0 15px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.field-search {
}

.lb-search {
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  margin-top: 3px;
  margin-right: 5px;
}

.lb-cus-title {
  font-size: 19px;
  margin: 10px 0;
}

.lb-nomal {
  font-size: 14px;
  margin: 10px 0;
}

.toolbar {
  justify-content: space-between;
}

.text-blue {
  color: #0073b7 !important;
}

.text-black {
  color: #333339 !important;
}

.text-yellow {
  color: #f39c12 !important;
}

.action-button {
  margin: 0 5px;
}

.action-view-link:hover i,
.action-edit-link:hover i {
  color: #2f78b9 !important;
  cursor: pointer;
}

.action-view-link i,
.action-edit-link i {
  color: #f39c12 !important;
}

.action-del-link:hover i {
  color: #2f78b9 !important;
  cursor: pointer;
}

.action-del-link i {
  color: red !important;
}

.action-his-link:hover i {
  color: #2f78b9 !important;
  cursor: pointer;
}

.action-his-link i {
  color: #6b6b72 !important;
}

.color-add {
  color: #015f97 !important;

}

.control-label {
  text-align: left !important;
}

.control-label-above {
  text-align: left !important;
  line-height: 31px;
}

.btn {
  border: 1px solid #ced4da !important;
}

.btn .caret {
  display: none;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px;
  border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #ccc;
  margin-top: 5px;
  margin-right: -10px;
}

.dropdown-submenu:hover > a:after {
  border-left-color: #fff;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}

.mgr5 {
  margin-right: 5px !important;
}

.mgr10 {
  margin-right: 10px !important;
}

.mgr20 {
  margin-right: 20px !important;
}

.mgt15 {
  margin-top: 15px !important;
}

.mgl10 {
  margin-left: 10px !important;
}

.mgb5 {
  margin-bottom: 5px !important;
}

.mgb10 {
  margin-bottom: 10px !important;
}

.mgb15 {
  margin-bottom: 15px !important;
}

.mg25 {
  margin-left: -20px !important;
}

.mg0 {
  margin: 0 !important;
}

.pd0 {
  padding: 0 !important;
}

.pd5 {
  padding: 5px !important;
}

.pd10 {
  padding: 10px !important;
}

.pd10 {
  padding: 10px !important;
}

.pdt10 {
  padding-top: 10px !important;
}

.pdt5 {
  padding-top: 5px !important;
}

.card-account {
  border: 1px solid #e3e3e3 !important;
}

.mgl-12 {
  margin-left: -12px !important;
}

.account-img {
  display: block;
  width: 60px;
  max-height: 60px;
}

.new-account-img {
  display: block;
  width: 200px;
  max-height: 200px;
  border-radius: 50%;
}

.jus-between {
  display: flex;
  justify-content: space-between;
}

.jus-end {
  display: flex;
  justify-content: flex-end;
}

.tab-content > .active {
  display: contents !important;
}

.textarea {
  height: 150px;
}

.card {
  margin-bottom: 1.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #c8ced3;
  border-radius: .25rem;
}

.card-body {
  flex: 1 1 auto;
  padding: 15px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
  border-radius: .25rem;
}

.rotateY180 {
  transform: rotateY(180deg);
}

.input-devextreme {
  border: 1px solid #968686;
  border-radius: 4px;
  padding-left: 10px;
}

.mgt5 {
  margin-top: 5px;
}

.mgl5 {
  margin-left: 5px;
}

.text-green {
  color: #2a972a;
}

#frmW18F1014 {
  .deleteAttach {
    cursor: pointer;
  }

  .note-info-schedule {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px;
    font-size: 12px;

    .note-info-text {
      font-weight: bold;
      margin-right: 5px;
      font-style: italic;
    }

    .note-by-schedule {
      display: flex;
    }
  }

}

#frmW18F1012 {
  .linkExpand {
    color: #0286f0e8;
  }

  .linkExpand:hover {
    text-decoration: underline;
  }

  .primaryGroup {
    font-size: 20px;
  }

  .secondaryGroup {
    font-size: 16px;
  }
}

.headerList {
  height: 40px;
  color: #fbfbfb;
  font-size: 16px;
  line-height: 40px;
  border-radius: 3px 3px 0 0;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#frmW18F1011 {
  .primaryGroup {
    font-size: 20px;
  }

  .secondaryGroup {
    font-size: 16px;
  }

}

.editorClassName {
  min-height: 300px;
  border: 1px solid #f3f0f0;
}

.item-attachtment {
  border: 1px solid #e4d6d6;
  border-radius: 3px;
  padding: 3px;
  background-color: rgba(255, 255, 255, 0.15);
  font-size: smaller;
  font-weight: 100;
  color: #999090;
}

.text-bold {
  font-weight: bold;
}

.text-light-blue {
  color: #00b2f0 !important;
}

button.btn-crm {
  border: none !important;
}

#frmW18F1023 {
  .avatarW18F1023 {
    height: 35px !important;
    margin-right: 5px;
    width: 35px !important;
    object-fit: cover;
    transition: all 150ms ease;
  }

  .dx-treelist-text-content:hover {
    .avatarW18F1023 {
      height: 45px !important;
      width: 45px !important;
    }
  }

  .groupSalesParentW18F1023 {
    font-weight: 600;
  }
}

.isDisabled {
  color: gray;
  cursor: not-allowed !important;
  opacity: 0.5;
  text-decoration: none;
}

.text-orange {
  color: orange !important;
}

#hamburgerContent {
  border-radius: 0;

  button {
    text-align: left;
    width: 100%;
  }

  .popover-content {
    padding: 5px 2px;

  }

}

#hamburgerContent.popover {
  z-index: 100 !important;
}

#changePass {
  input {
    border-top: 0 white !important;
    border-left: 0 white !important;
    border-right: 0 white !important;
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.075);
    border-top: 2px solid #ccc;
    border-radius: 0 !important;
    padding: 2px 2px !important;
    font-size: 30px;
    color: black;
  }

  .labelChange {
    font-size: 12px;
  }

  #passwordTitle {
    font-weight: 600;
    text-align: center;
  }

  #passwordRequired {
    position: absolute;
    left: 20%;
    font-size: 13px;
  }

  .recomended-text {
    color: #fbb99e;
  }

  .underLine {
    text-decoration: line-through;
    text-decoration-color: #CCCCCC;
  }

  .buttonChangePass {
    width: 100%;
    background-color: #5f9ed2f7;
    color: #ffffff;
    font-weight: 600
  }
}

.changePassCss {
  .title-bar {
    border-bottom: none;
  }
}

#scheduleTemplate {
  .dx-group-row {
    color: rgba(12, 5, 5, 0.33);
  }

  .dx-group-row td[role=gridcell] {
    background-color: #f7f7f7;

  }

  .dx-datagrid-expand {
    background: #f7f7f7;
  }

  .dx-header-row td {
    background-color: #337ab7 !important;
    color: white !important;

    .dx-datagrid-text-content {
      color: white !important;
    }
  }

}

#dashboard {
  #txtSearchInput {
    &:focus {
      box-shadow: none !important;
    }

    height: 36px;
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
  }

  #container-button-option {
    height: 36px;
    border-left: none;
    border-top: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .group-filter {
    justify-content: space-between;
    content: inherit;
    flex-wrap: wrap;

    #cbbFlagView {
      max-width: 130px;
    }

    .dx-customCRM-icon-clear {
      width: 32px;
      height: 32px;
      border-radius: 4px;
      position: absolute;
      top: 2px;
      right: 32px;
      font-size: 18px;
      color: #999999;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .fa-angle-down {
      width: 32px;
      height: 32px;
      border-radius: 4px;
      position: absolute;
      top: 2px;
      right: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:hover {
        background-color: #e6e6e6;
      }
    }
  }

  @media only screen and (max-width: 1199px) {
    #dashboardButton {
      float: left;
    }
  }

  #dashboardButton {
    text-align: right;

    #user-contain {
      overflow: hidden;

      a.fa-user-circle {
        display: block;
      }
    }
  }

  .selectedButton {
    background: #337ab7;
    color: white;
  }

  .timeSchedule {
    font-weight: 600;
  }
}

.W18F1019Pop {
  #shareCustomerNameW18F1019 {
    font-weight: bold;
  }

}

#W18F1019 {
  #shareEveryOneW18F1019 {
    position: relative;
    left: 11px;
  }

  #shareUserW18F1019 {
    position: relative;
    top: -4px;
  }

  fieldset {
    border: 1px solid #c0c0c0 !important;
    margin: 0 2px !important;
    padding: 0.35em 0.625em 0.75em !important;
  }

  legend {
    width: 104px;
    border: none;
  }
}

.mgb0 {
  margin-bottom: 0 !important;
}

.mgt0 {
  margin-top: 0 !important;
}

.mgl0 {
  margin-left: 0 !important;
}

.mgr0 {
  margin-right: 0 !important;
}

#W18F1019 {
  #search-button-permission {
    color: white;
    background-color: rgb(0, 148, 236);
    position: absolute;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    z-index: 10;
    right: 0;
    padding: 10px 0 0 0;
    height: 35px;
    bottom: 0;
    width: 35px;
  }

  #search-share-permission {
    border-radius: 22px;
  }

  #frmSharePermission {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding: 0 20px;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 70vh;

    .Share-Rows:hover {
      .Share-Person-Name {
        color: #e61414b5;
      }

      cursor: pointer;

      .Share-Content {
        box-shadow: rgba(226, 127, 127, 0.33) -3px 3px 0 0;
      }
    }

    .Share-Content {
      border-left: 1px solid #d7c9c9;
      border-left: 1px solid #d7c9c9;
      border-bottom: 1px solid #d7c9c9;
      border-radius: 9px;
      margin-top: 5px;
      padding: 10px 6px;
      flex-wrap: wrap;
      align-items: center;
      box-shadow: rgba(53, 168, 236, 0.32) -3px 3px 0 0;

      .Avatar-Share-Person {
        width: 50px;
        height: 50px;
        border-radius: 10px;
        object-fit: cover;
        align-self: flex-start;
      }

      .Share-Per {
        width: 120px;

        .toggle-wrapper {
          .bRadius {
            border-radius: 13px;
          }

          .bgGray {
            background-color: gainsboro;
          }

          .bgOrange {
            background-color: #f4bd59;
          }

          .bgGreen {
            background-color: darkseagreen;
          }

          .text-white {
            color: white
          }

          .toggleContainer {
            border-radius: 13px;
            height: 30px;

            .toggleOption {
              top: -4px;
              line-height: 34px;
            }

          }

        }
      }

      .Share-Info {
        flex-basis: 80%;
        padding: 0 5px;
        transition: all 0.2s ease-out;

        .Share-Person-Name {
          font-weight: bold;
          font-size: 18px;
        }

        .Share-Person-Location {
          color: rgba(82, 78, 78, 0.65);
          font-weight: 500;
        }
      }
    }
  }
}

#clRequiredField {
  .page-requiredField {
    margin: 0 10px;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    border: 1px solid #ddd;
  }

  .title-field {
    color: #000000bd;
    margin-top: -10px;
    width: fit-content;
    padding: 0px 20px;
    margin-left: 20px;
  }

}

.mgl25 {
  margin-left: 25px !important;
}

.mgr25 {
  margin-right: 25px !important;
}

.mgt25 {
  margin-top: 25px !important;
}

.mgb25 {
  margin-bottom: 25px !important;
}

.text-no-data {
  width: 100%;
  text-align: center;
  display: block;
  color: lightgray;
}

.height-textarea {
  min-height: 140px !important;
}

div .text-transform {
  text-transform: uppercase !important;
}

#frmW19F1010 {
  .dx-treelist-action {
    text-align: center !important
  }

  .dx-header-row {
    color: black;
    font-weight: bold;
  }
}

#W19F1001 {
  #approveNoteW19F1001 {
    font-weight: bold;
    font-style: italic;
    color: #e2323278;
  }

  @-webkit-keyframes pulse {
    0% {
      opacity: 0;
    }
    10% {
      opacity: .20;
      transform-origin: 30% 60%;
      transform: scale(5);
      transition: all .2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
    }
    10% {
      opacity: .25;
      transform-origin: 50% 50%;
      transform: scale(3);

    }
    100% {
      opacity: .40;
      transform: scale(1);
    }
  }

  #passStampW19F1001 {
    position: absolute;
    left: 0;
    top: -22px;
    height: 90px;
    width: 170px;
    opacity: 0;
    -webkit-animation-name: pulse;
    animation-name: pulse;
    animation-delay: 2s;
    -webkit-animation-delay: 2s;
    -webkit-animation-duration: 0.73s;
    animation-duration: 0.73s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  #passInfoContainerW19F1001 {
    font-weight: bolder;
    font-size: 15px;

    #passInfoW19F1001 {
      margin-left: 5px;

      div:first-of-type {
        z-index: 1000;
      }

      display: flex;

      img {
        margin-top: 5px;
        width: 30px;
        height: 30px;
        object-fit: cover;
        border-radius: 40px;
      }

      #approvePersonW19F1001 {
        color: #000000;;
        font-size: 16px;
      }
    }
  }

  #shareContainerW19F1001 {
    padding: 5px;
    border-radius: 5px;
    overflow: auto;
    resize: vertical;
    height: auto;

    .shareListW19F1001 {
      display: inline-block;

      img {
        width: 30px;
        height: 30px;
        object-fit: cover;
        border-radius: 40px;
      }
    }
  }

}

.fixed-height {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.mgt10 {
  margin-top: 10px !important;
}

.overflow-one-line {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}

/* total width */
::-webkit-scrollbar {
  background-color: transparent;
  width: 5px;
  height: 5px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 100px;
  border: 4px solid darkgrey;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none
}

.text-darkcyan {
  color: darkcyan;
}

.fc-button-primary {
  color: #337ab7;
  background-color: white;
  border-color: #337ab7;
  outline: none !important;

  &:hover {
    color: white;
    background-color: #337ab7;
  }

  &:focus {
    outline: 0;
    box-shadow: none;
  }
}

.fc-left {
  color: gray;
  //border: 1px solid gray;
  padding: 5px;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fc-day-header {
  height: 40px;
  line-height: 40px;
  background-color: aliceblue;
  color: gray;
}

#MailMasterComponent {
  .customInput {
    border-top: 0 white !important;
    border-left: 0 white !important;
    width: 100%;
    border-bottom: 1px solid #00000038;
    border-right: 0 white !important;
  }
}

.tox-notifications-container {
  display: none !important;
}

@media (max-height: 768px) {
  #Template-Mail-Body {
    height: 420px !important;
  }
}

#Template-Mail-Body {
  height: calc(100vh - 250px);
  overflow: auto;
}

#ClsStatus-switch {
  .PageStatusSwitch {
    border: 1px solid #ddd;
    padding: 0 15px;
    margin-bottom: 15px;
  }

  .pdSwitch {
    padding-left: 30px !important;
  }
}

#W18F1051 {
  #employeeW18F1051 {
    padding: 0;
    border-radius: 5px;
    overflow: auto;
    resize: vertical;
    height: auto;

    .employeeListW18F1051 {
      display: inline-block;
      border: 1px solid #ccc;
      border-radius: 5px;
      padding: 5px 20px 5px 5px;
      position: relative;
      height: 35px;

      img {
        width: 25px;
        height: 25px;
        object-fit: cover;
        border-radius: 30px;
      }
    }

    .employeeDelW18F1051 {
      position: absolute !important;
      top: 9px;
      right: 4px;
      font-size: 15px;
      width: 12px;
      height: 12px;
      color: #AAA !important;
      cursor: pointer;
    }
  }

  .employeeHref {
    color: #337ab7;
    cursor: pointer;
  }

  .employeeHref:hover {
    text-decoration: underline;
  }
}

.view-attachment {
  position: relative;
  margin: 15px;
  max-width: 70px;

  img {
    max-width: 50px;
    max-height: 70px;
    width: 70px;
    height: 70px;
    object-fit: contain;
    border-radius: 10px;
    margin-bottom: 10px;
  }

  .img-background {
    max-width: 70px;
    object-fit: cover;
    background-color: lightgray;
  }

  .deleteAttach {
    position: absolute;
    top: -8px;
    right: -8px;
    border-radius: 70px;
    background-color: whitesmoke;
    box-shadow: 0 0 7px 1px lightgrey;
    overflow: hidden;
    padding: 5px;
  }
}

.view-loading {
  max-width: 70px;
  max-height: 70px;
  width: 70px;
  height: 70px;
  border-radius: 10px;
  background-color: lightgray;
  text-align: center;
  line-height: 70px;
  margin: 15px;
}

.list-attach {
  border: 1px solid lightgrey;
  border-radius: 4px;
  padding: 15px;
  flex-wrap: wrap;
  display: flex;

  label {
    word-break: break-all;
    display: flex;
    flex-direction: column;
  }
}

.Square-Info {
  width: auto;
  height: auto;
  padding: 2px 4px;
  border-radius: 3px;
}

#W19F1031Container {
  margin: 0;
  @media only screen and (max-width: 1199px) {
    #masterW19F1031 {
      height: auto !important;

      #menuButtonW19F1031 {
        display: flex;
        flex-wrap: wrap;

        #buttonHistoryW19F1031 {
          margin-left: auto;
        }
      }
    }
  }

  #masterW19F1031 {
    transition: width 0.2s;
  }

  #W19F1031 {
    margin: 0 15px;
    height: 100%
  }

  .grid-title {
    margin-left: 10px;
  }
}

.height100 {
  height: 100%
}

#W19F1030 {
  @media only screen and (min-width: 990px) {
    #nonew19f1030 {
      display: flex;
    }
    .noneHeader {
      display: none;
    }
    #styleW19F1030 {
      border-right: 1px solid #f4f2f2;
    }
    .footer-evaluation {
      display: flex;
      min-height: 100px;
      max-width: 50px;
    }
  }
  @media only screen and (max-width: 990px) {
    #nonew19f1030 {
      display: none;
    }
    .noneHeader {
      display: block;
    }

    .footer-evaluation {
      display: block;
      height: 100%;
      padding: 10px 0;
    }
    .evaluation_content {
      padding-bottom: 0 !important;
    }
    .col-md-5 {
      flex-direction: column;
      position: initial;
    }
    .image-sale-evaluation {
      position: relative;
    }
	.evaluation {
	  right: -15px;
      position: relative;
	}
  }

  .title-evaluation {
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 20px;
    font-size: 25px;
    color: black;
    padding-left: 20%;
  }

  .image-sale-evaluation {
    height: 100%;
    width: 100%;
    padding: 15px 15px 5px 15px;
    border: 1px solid #f4f2f2;

    .img-evaluation {
      width: 80px;
      height: 80px;
      border: 1px solid #f4f2f2;
      border-radius: 10px;
      object-fit: cover;
    }
  }

  .content-evaluation {
    display: flex;
    flex-direction: column;
    float: left;
    border-width: 10px;
    width: 100%;
    height: 100%;
    padding-left: 10px;

    .content_name {
      padding-bottom: 5px;
      height: 60%;
      color: #015f97;
      font-weight: bold;
      object-fit: cover;
    }

    .content_date {
      height: 20%;
      color: #a09c9a;
    }
  }

  .evaluation {
    padding-bottom: 30px;
    border-width: 10px;
    width: 100%;
    height: auto;
    margin-right: 10px;
    float: left;
  }

  .ribbon {
    line-height: 30px;
    user-select: none;
    padding: 0 10px 10px 20px;
    width: auto;
    height: 30px;
    position: absolute;
    bottom: 10px;
    right: 0;
    color: white;

    &:after {
      content: '';
      width: 0;
      height: 0;
      border-top: 15px solid transparent;
      border-bottom: 0 solid transparent;
      border-left: 15px solid white;
      border-right: 0 solid white;
      position: absolute;
      left: 0;
      bottom: 0;
    }

    &:before {
      content: '';
      width: 0;
      height: 0;
      border-top: 0 solid transparent;
      border-bottom: 15px solid transparent;
      border-left: 15px solid white;
      border-right: 0 solid white;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .jss7 {
    border: 1px solid lightgrey;
    height: 32px;
    display: block;
    padding: 4px 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    margin-right: 10px;
    border-radius: 100px;
    margin-bottom: 10px;
    cursor: pointer;
	max-width: 165px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .jss7:hover {
    background-color: #E4E9F2;
    font-weight: bold;
  }

  .mendation {
    padding-bottom: 10px;
    height: 100%;
  }

  .footer-evaluation {
    float: left;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

}

.loadingExcel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 2;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
}

#frmW18F1050 {
  margin: 15px;

  #buttonGroupW18F1050 {
    display: flex;
  }
}

.evaluateTypeColor {
  width: 100px;
  height: 34px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.evaluateTypeHandle {
  cursor: pointer;
  margin: 5px 5px 5px 15px;

  .evaluateTypeImg {
    width: 17px;
    height: 17px;
    cursor: pointer;

  }
}

.editor-content, .editor-recommendation {
  img {
    width: 100% !important;
    height: 100% !important;
  }
}

#frmW20F1001 {
  #masterW20F1001 {
    transition: width 0.2s;
  }
}

#frmW20F1000 {
  #search-user-profile {
    border-radius: 5px;
  }

  #search-button-user-profile {
    background-color: #EEEDED;
    color: #0094ec;
    width: 50px;
    height: 34px;
    border-radius: 5px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

#frmW20F1002 {
  @media only screen and (max-width: 1560px) {
    .responsiveColumnW20F1002 {
      width: 100% !important;
    }
  }
}

#frmW17F1026 {
  .form-control[disabled], .dx-state-disabled {
    background-color: #eee;
    opacity: 1;
  }

}

.list-child-customer-dropdown {
  padding: 10px 5px;
  cursor: pointer;
}

.list-child-customer-dropdown:hover {
  background: #c7d3f5eb
}

.text-cornflowerblue {
  color: cornflowerblue;
}

.text-darkorange {
  color: darkorange;
}

#selected-switch {
  background: #006ff9bd;
  position: absolute;
  border-radius: 5px;
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  color: white;
  align-items: center;
  font-weight: bold;
  box-shadow: #959595 2px 2px 4px 0;
  cursor: pointer;
  transition: transform 0.2s ease;
}

#W18F1060 {
  @media only screen and (max-width: 1135px) {
    .btn-pdf-report, .btn-excel-report {
      margin-top: 5px;
    }
  }

  @media only screen and (max-width: 925px) {
    .btn-popup-open {
      margin-top: 5px;
    }
  }

  @media only screen and (max-width: 675px) {
    #Switch-Button {
      margin-top: 5px;
    }
  }
}


#frmW18F4070 {
  @media only screen and (max-width: 1199px) {
    #areaCol {
      margin-top: 15px;
    }
  }

  @media only screen and (max-width: 991px) {
    .resDate {
      margin-top: 15px;
    }
  }
}

@keyframes blinking{
    50% {color: #FF3D71;}
}

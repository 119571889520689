.W17F0043{
  padding: 20px;
  button.btn-crm{
    margin: 10px 4px 0 0;
    float: right;
  }
  .grid-title{
    font-size: 18px;
    margin-bottom: 10px;
    span{
      color: grey;
      font-weight: 100;
      font-style: italic ;
      font-size: 12px;
      padding-left: 10px;
    }
    img{
      width: 20px;
      height: 20px;
    }
  }
  .title-column{
    width: 100%;
    border-bottom: 1px solid lightgray;
    padding: 10px;
    color: $crmBackGroundColorMain;
    font-weight: bold;
  }
  .list-column{
    margin: 0 4px;
    border: 1px solid lightgrey;
    border-radius: 2px;
    padding: 0;
    display:flex;
    flex-direction: column;
  }
  h3{
  }
  .list-task{
    flex-grow: 1;
    padding: 10px;
    transition: all 2s;
  }
}
.dx-datagrid-headers {
  .dx-datagrid-table {
    .dx-row {
      td {
        padding-top: 10px;
        padding-bottom: 10px;
        background-color: #e5e5e5;
      }
    }
  }
  .dx-datagrid-rowsview {
    .dx-select-checkboxes-hidden {
      .dx-select-checkbox {
        display: block;
      }
    }
  }
}

.dx-datagrid-headers {
  dx-datagrid-table .dx-row > td {
    border-bottom: none;
  }

  .dx-header-row td {
    &:not(.custom-align) {
      text-align: center !important;
    }
    font-weight: bold;
    background-color: $dataGridHeaderBgColor !important;
    border-bottom: none;

    .dx-datagrid-text-content {
      color: $dataGridHeaderFontColor;
    }
  }
}

.dx-datagrid .dx-row > td {
  background-color: $dataGridBgEvenColor;
}
.dx-datagrid .dx-row-alt > td {
  background-color: $dataGridBgOddColor;
}
.dx-datagrid-rowsview .dx-selection.dx-row > td,
.dx-datagrid-rowsview .dx-selection.dx-row:hover > td {
  background-color: $dataGridSelectedBgColor;
  color: $dataGridSelectedFontColor;
  a {
    color: $dataGridSelectedFontColor !important;
  }
}
.dx-checkbox-checked .dx-checkbox-icon {
  background-color: $dataGridCheckBoxBgColor;
}
.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td.dx-command-edit.dx-command-edit-with-icons .dx-link,
.dx-datagrid
  .dx-datagrid-content
  .dx-datagrid-table
  .dx-row
  > td.dx-cell-modified:not(.dx-field-item-content).dx-command-edit.dx-command-edit-with-icons
  .dx-link,
.dx-datagrid
  .dx-datagrid-content
  .dx-datagrid-table
  .dx-row
  > td.dx-datagrid-invalid:not(.dx-field-item-content).dx-command-edit.dx-command-edit-with-icons
  .dx-link {
  color: $dataGridActionButtonColor;
}
.dx-checkbox-indeterminate .dx-checkbox-icon {
  background-color: $btnFontColor;
  color: white;
  font-weight: bold
}
.dx-pager .dx-pages .dx-selection,
.dx-pager .dx-page-sizes .dx-selection {
  background-color: $pagingPerPageBackground;
  color: $pagingPerPageColor;
}
.dx-datagrid {
  color: $dataGridCellFontColor;
}

.dx-pager .dx-pages .dx-info {
  color: $pagingInfoColor;
  opacity: 1;
}
.grid-container {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  border-radius: 5px;
  background-color: $gridContainerBgColor;
  border: 1px solid $gridContainerBorderColor;
  .dx-widget:not(.report-list-grid) {
    .dx-gridbase-container > .dx-datagrid-rowsview.dx-scrollable > .dx-scrollable-wrapper {
    padding-bottom: 5px;}
    .dx-datagrid-rowsview .dx-datagrid-content {
      margin-bottom: 5px !important;
    }
  }
}

.dx-datagrid .dx-column-lines td {
  border-right: 1px solid rgb(236, 241, 246);
}

.grid-title {
  color: #2f78b9;
  font-weight: bold;
  font-size: 14px;
  margin: 0;
  height: 25px;
  padding: 2px 0 0 2px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  background-color: $dataGridBgEvenColor;
  /*background-image: linear-gradient(rgb(190, 204, 216), rgb(202, 218, 222), rgb(190, 204, 216));*/
}

.dx-datagrid-rowsview .dx-row.dx-row-lines:first-child > td,
.dx-datagrid-rowsview .dx-row.dx-row-lines:first-child > tr > td {
  border-top: 1px solid $gridContainerBorderColor;
}

.dx-datagrid .dx-datagrid-rowsview .dx-data-row td.dx-pointer-events-none,
.dx-datagrid .dx-datagrid-rowsview .dx-freespace-row td.dx-pointer-events-none,
.dx-datagrid .dx-datagrid-rowsview .dx-virtual-row td.dx-pointer-events-none,
.dx-datagrid .dx-datagrid-headers .dx-row td.dx-pointer-events-none {
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
}

.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td.dx-command-select,
.dx-datagrid
  .dx-datagrid-content
  .dx-datagrid-table
  .dx-row
  > td.dx-cell-modified:not(.dx-field-item-content).dx-command-select,
.dx-datagrid
  .dx-datagrid-content
  .dx-datagrid-table
  .dx-row
  > td.dx-datagrid-invalid:not(.dx-field-item-content).dx-command-select {
  padding-left: 4px;
}
.dx-checkbox-icon {
  border: 1px solid $crmFontColor04;
}
.paging-crm {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
  border-top: 1px solid #e0e0e0;

  .pageOf,
  .pagePer {
    margin-left: 5px;
    margin-right: 5px;
    text-align: center;
    height: 20px;
    border-radius: 5px;
  }
  .pageOf {
    width: 4%;
  }
  .first span,
  .next span,
  .prev span,
  .last span {
    color: $crmFontColor04;
  }
  .first,
  .next {
    padding: 6px 6px;
    min-width: 18px;
  }
  .prev,
  .last {
    padding: 6px 6px;
    min-width: 18px;
  }
  .slash {
    margin: 0 3px;
    color: #81aacf;
  }
}
.dx-invalid-message .dx-overlay-wrapper .dx-overlay-content {
  float: right;
  transform: translate(1px, -35px) !important;
}

.dx-popup-content:not(.dx-visibility-change-handler .dx-popup-content) {
  padding: 0 !important;
}

.dx-scrollable-container {
  background-color: $gridContainerBgColor;
}

.dx-datagrid .dx-row-lines > td {
  border-bottom: 1px solid $gridContainerBorderColor;
}

//update UI paging on tablet and mobile view.
@media only screen and (max-width: 960px) {
  .paging-crm {
    .txt-line-number {
      display: none;
    }
    .hide-mobile,
    .pagePer {
      display: none;
    }
  }
}

.dx-datagrid {
  .dx-row {
    td {
      vertical-align: middle !important;
    }
  }
}
.disabledSearch {
  display: none !important;
}

.dx-dropdowneditor-overlay .dx-popup-content {
  padding: 0 !important;
  .dx-datagrid-focus-overlay {
    border: none !important;
  }
  .dx-scrollable-content,
  .dx-datagrid-table {
    max-width: 100%;
  }
}

.grid-full-width {
  position: relative;
  .grid-container {
    overflow-x: scroll;
    padding-top: 38px;
    .toolbar-container {
      position: absolute;
      left: 15px;
      width: calc(100% - 30px);
      top: 0;
    }
  }
}

.dx-datagrid .dx-datagrid-borders > .dx-datagrid-pager {
  padding-top: 0;
}

.dx-datagrid {
  .dx-row {
    & > td {
      border-right: none !important;
      border-left: none !important;
    }
    &.dx-state-hover:not(.dx-header-row) {
      .grid-action-hover {
        display: table-cell !important;
      }
    }
    .grid-action-hover, .grid-action-hover.hidden {
      display: none;
    }
    .grid-action-opacity {
      opacity: 0;
    }
  }
}

.dx-datagrid .dx-row-lines > td.grid-action-hover {
  border-top: 1px solid #E4E9F2;
  border-bottom: 1px solid #E4E9F2;
}

.dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row):not(.dx-row-focused) > td.grid-action-hover {
  box-shadow: none;
}

